import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from '../../../environments/environment';
import { Nationality } from "./nationality";


@Injectable({
  providedIn: 'root'
})
export class NationalitiesService {

  constructor(private http: HttpClient) {}

  fetchAll(): Observable<Nationality[]> {
    return this.http.get(`${environment.apiBase}public/nationalities`)
                    .pipe(map(x => x["content"]));
  }

}
