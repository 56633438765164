

export const VISA_CATEGORIES = [
  { id: 'TO', description: 'Tourist' },
  { id: 'BU', description: 'Business' },
  { id: 'ST', description: 'Student' },
  { id: 'EM', description: 'Employment' },
  { id: 'DI', description: 'Diplomatic' },
  { id: 'IN', description: 'International/Organizational' },
  { id: 'UN', description: 'UN' },
  { id: 'VOA', description: 'VOA' }
]
