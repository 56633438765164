

export const CERTIFICATE_TYPES = [
  { id: 'P', description: 'Police Clearance' },
  { id: 'D', description: 'Driving License' },
  { id: 'C', description: 'Dual Citizenship' },
  { id: 'M', description: 'Movement' },
  { id: 'A', description: 'Death' },
  { id: 'E', description: 'Emblem' },
  { id: 'O', description: 'Other' }
];
