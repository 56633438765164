

export const PAYMENT_MODES = [
  {
    id: "C",
    description: "Card payment",
    receiptDescription: "Payment reference #"
  },
  {
    id: "B",
    description: "Bank draft",
    receiptDescription: "Draft impression"
  },
  {
    id: "M",
    description: "Money order",
    receiptDescription: "Money receipt #"
  }
];
