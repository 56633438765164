

export const ALL_SERVICE_TYPE_IDS = {
  NEW_PASSPORT: '1899a660-a6cc-474f-a5e8-49029abb1944',
  VISA: 'd473ba80-b8b8-4480-8448-b0ef038b1752',
  BIRTH_CERT: '99aac9f5-20ec-4c25-b891-2a58f2b554e7',
  ATTESTATION: '08da9b9c-33e9-4a79-8e4e-696002ad3258',
  CONS_CERT: '61851060-14ce-49f4-ab75-94b60237893f',
  NO_VISA: '6725a122-446c-4691-8dc7-5b76b7cfc69b',
  PASSPORT_REISSUE: '67d41e96-485b-4382-a73d-a5b47da7fbd8'
};


export interface ServiceType {
  id: string;
  description: string;
}


export interface ServiceTypeDetails {
  description: string;
  documents: ServiceTypeDocument[];
}


export interface ServiceTypeDocument {
  document_type_description: string;
  required: boolean;
  template_url?: string;
}
